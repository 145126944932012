:root {
  --color-success: #3ECD5E;
  --color-primary: #2196F3;
  --color-secondary: #8C929D;
  --color-danger: #FF0000;
  --color-warning: #F9B234;
  --color-rose: #CD3E94;
  --color-white: #fff;
  --color-dark: #0A0A23;
  --color-gray: #F0F2F5;
  --color-gray-secondary: #ced0d1;
  --color-beige: #F7F7F7;
  --color-silver: #54656F;
}

body {
  background-color: #0A0A23;
  background-image: url('./assets/bakground.png');
  background-size: cover;
  background-repeat: no-repeat;
  /* background: linear-gradient(180deg, transparent 40%, transparent 74%);  */
  min-height: 100vh !important;
  font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande";
  font-size: medium;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  padding-top: 4.3rem;
}

.textarea-group {
  margin-left: 4px;
  font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande";
  font-size: 12px;
  line-height: 24px;
  text-decoration: none solid rgb(78, 94, 106);
  text-align: left;
  vertical-align: bottom;
  word-spacing: 0px;
  color: #40474d;
  user-select: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 200;
  padding: 4.2px 16px;
  resize: none;
  height: 34px;
  border: 1.0px solid var(--color-secondary);
  outline: 13.5px #3F51B5;
  transition: border 0.2s ease;
  overflow: auto;
}

.textarea-group::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.textarea-group:active,
.textarea-group:focus {
  border: 1.0px solid #3F51B5;
}

.textarea-group::placeholder {
  color: rgb(189, 181, 181) !important;
  font-size: 13px;
}


.table-responsive {
  background-color: #FFFFFF;
  min-height: 250px !important;
  border: 1px solid rgb(224, 217, 217);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

@media (width < 650px) {
  body {
    padding-top: 5.2rem;
    background-image: none;
    background-color: white;
  }

  footer {
    color: #787F85 !important;
  }
}

@media (min-width: 768px) {
  body>.navbar-transparent {
    box-shadow: none
  }

  body>.navbar-transparent .navbar-nav>.open>a {
    box-shadow: none
  }
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 16px;
}

.mr-4 {
  margin-right: 32px;
}

.mr-5 {
  margin-right: 64px;
}

.btn {
  font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande";
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 8px;
  padding-bottom: 8px;
}

.btn:hover {
  opacity: 0.8;
  transition-delay: 20s;
}

.p-tag {
  font-size: 11px;
  padding: 2px 6px;
}

.p-tag-secondary {
  background-color: var(--color-secondary);
}

.p-button {
  border-radius: 4px;
}

.alert {
  display: flex;
  margin-top: 4px;
  justify-content: space-between;
}

.alert-success {
  background-color: #5cb8a2;
}

.ml-1 {
  margin-left: 2.5px;
}

.ml-2 {
  margin-left: 5px;
}

.ml-3 {
  margin-left: 9.0px;
}

.ml-4 {
  margin-left: 12px;
}

.pl-1 {
  padding-left: 2.5px !important;
}

.pl-2 {
  padding-left: 5px !important;
}

.pl-3 {
  padding-left: 9.0px !important;
}

.pl-4 {
  padding-left: 15px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-6 {
  padding-left: 25px !important;
}

.pr-1 {
  padding-right: 2.5px !important;
}

.pr-2 {
  padding-right: 5px !important;
}

.pr-3 {
  padding-right: 9.0px !important;
}

.pr-4 {
  padding-right: 15px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-6 {
  padding-right: 25px !important;
}

.btn-sm {
  margin-top: 0;
}

.p-multiselect-panel {
  left: 30%;
  width: 230px;
}

.p-autocomplete-input {
  width: 100% !important;
  height: 2.3rem;
}

.p-autocomplete-dropdown {
  height: 2.3rem;
}

.p-dropdown-items {
  padding: 0;
  font-size: 13px;
}

.p-dropdown-item {
  border-bottom: 1px solid #dfdfdf !important;
}

.p-dropdown-filter {
  height: 2.3rem !important;
}

.p-card-body {
  padding: 0 !important;
}

.p-card-content {
  padding: 0 !important;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.ml-1 {
  margin-left: 1px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-3 {
  margin-left: 4px;
}

.ml-4 {
  margin-left: 8px;
}

.ml-5 {
  margin-left: 16px;
}

.mr-1 {
  margin-right: 1px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-3 {
  margin-right: 4px;
}

.mr-4 {
  margin-right: 8px;
}

.mr-5 {
  margin-right: 16px;
}

.f-18 {
  font-size: 30px;
  padding: 0;
  color: #535252;
}

.pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}