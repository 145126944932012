.navbar {
    background: #ffffff;
    height: 63px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
}

a.logo-nav {
    font-weight: 550;
    padding: 0px 100px;
    padding-bottom: 6px;
}

.navbar ul {
    padding-top: 3px;
    float: right;
    margin-right: 20px;
    user-select: none;
}

.navbar ul li {
    display: inline-block;
}

.navbar ul li label {
    color: black;
    font-size: 15px;
    margin-right: 22px;
    border-radius: 3px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 5px 10px;
    cursor: pointer;
}

.navbar ul li i {
    font-size: 22px;
}

.navbar label.active,
.navbar label:hover {
    color: #6A9BF0;
    transition: .5s;
}

.checkbtn:hover {
    color: black !important;
}

.checkbtn {
    font-size: 30px;
    float: right;
    line-height: 60px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
    margin-left: 5px;
}

#check {
    display: none;
}

@media (width < 650px) {
    a.logo-nav {
        font-weight: 550;
        padding: 0px;
        padding-right: 80px;
        justify-content: center;
        padding-bottom: 6px;
    }
}

@media (max-width: 952px) {
    .navbar {
        display: flex;
        flex-direction: column-reverse;
    }

    .navbar a.logo {
        padding-left: 10px;
        margin-top: 102px;
        width: 15.0rem;
    }

    .navbar ul li label {
        font-size: 16px;

    }
}

@media (max-width: 858px) {

    .checkbtn {
        display: block;
    }

    .navbar ul {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: #ffffff;
        top: 60px;
        left: -100%;
        text-align: center;
        transition: all .5s;
    }

    .navbar ul li {
        display: block;
        margin: 50px 0;
        line-height: 30px;
    }

    .navbar ul li label {
        font-size: 20px;
    }

    .navbar label:hover,
    .navbar label.active {
        background: none;
        color: #6A9BF0;
    }

    #check:checked~ul {
        left: 0;
    }
}