.form-group{
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-top: 0;
}

.form-group label{
    font-size: 12.7px;
    font-weight: 550;
}