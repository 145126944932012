.data-called {
    position: relative;
    background-color: var(--color-white);
}

.called-info {
    color: var(--color-secondary);
    display: flex;
    flex-direction: column;
    font-weight: 550;
    font-size: 14px;
}

.called-info label {
    margin-bottom: 5px;
    user-select: none;
}

.called-info i {
    font-size: 18px;
    margin-right: 3px;
}

.called-info .info-active {
    color: var(--color-dark);
}

.called-info .info-active i {
    color: var(--color-primary);
    ;
}

.null-comment {
    min-width: 120px;
    color: var(--color-secondary);
    ;
    font-size: 14px;
    padding-left: 3px;
    font-weight: 100;
}

.card-info {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
}

.dialog-preview-called {
    width: 95vw;
    overflow-y: hidden;
    max-height: 95% !important;
    height: 100vw;
    position: relative;
}

.p-dialog-content {
    padding: 0 !important;
}

.p-dialog-header {
    padding: 15px 20px !important;
    border-bottom: 1px solid #dadada;
}

.p-dataview-content {
    padding-top: 0;
    padding-bottom: 0;
}

.attchament-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid #aaaaaa;
}

.card-image {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
}

.card-called {
    border-radius: 10px;
    max-height: 80vh;
}

.card-called::-webkit-scrollbar {
    width: 7px;
}

.card-called::-webkit-scrollbar-track {
    background-color: var(--color-white);
}

.card-called::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary);
    border-radius: 5px;
}

@media (min-width: 700px) {
    .card-called {
        border-radius: 10px;
        overflow-y: auto;
        overflow-x: auto;
    }

    .card-called::-webkit-scrollbar {
        width: 9px;
    }

    .card-called::-webkit-scrollbar-track {
        background-color: var(--color-gray);
        display: none;
    }

    .card-called::-webkit-scrollbar-thumb {
        background-color: var(--color-secondary);
        border-radius: 50px;
    }
}

.p-button-filter {
    height: 3.152rem;
    margin-left: 5px !important;
}

.p-button-select-assignee {
    height: 3.152rem;
}

.button-select {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 32px;
    align-items: center;
    width: 100%;
    background-color: var(--color-gray);
    z-index: 100;
    cursor: pointer;
}

@media (max-width: 600px) {
    .called-header {
        flex-wrap: wrap-reverse;
    }

    .p-button-filter {
        height: 3.152rem;
        margin: 5px 0px 5px 0px !important;
        width: 50%;
    }

    .p-button-select-assignee {
        width: 15%;
    }

    .dropdown-assignee {
        width: 85%;
    }

    .assignee-select {
        width: 100%;
        margin-bottom: 7px;
    }

    .dialog-preview-called {
        width: 95vw;
        overflow-y: auto;
        height: 100% !important;
    }
}

.p-input-icon-left {
    position: relative;
    display: inline-block;
}

.p-input-icon-left i {
    position: absolute;
    left: 1rem;
    top: 1.15rem;
    color: var(--color-dark);
    z-index: 1;
}

.input-icon {
    padding-left: 2.5rem;
    margin-left: 5px;
}

.p-dropdown-icon-left {
    position: relative;
    display: inline-block;
}

.p-dropdown-icon-left i {
    position: absolute;
    left: 1rem;
    top: 0.4rem;
    color: var(--color-dark);
    z-index: 1;
}

.dropdown-icon {
    padding-left: 1.7rem;
    margin-left: 5px;
}

.filter-card {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-weight: 500;
}

.p-inputgroup-addon {
    position: relative;
    cursor: pointer;
    background-color: var(--color-gray);
    border: none;
    font-size: 18px;
}

.input-group:disabled {
    background-color: var(--color-gray);
}

.p-inputgroup-addon:disabled {
    background-color: var(--color-gray);
    color: var(--color-secondary);
}

.p-inputgroup-addon:hover {
    opacity: 0.90;
}

.p-inputgroup-addon:disabled {
    opacity: 0.90;
}

.btn-plus {
    height: 36px;
    color: var(--color-silver);
    font-size: 25px;
    padding: 0;
    font-weight: 900;
}

.btn-plus i {
    height: 22px;
    display: flex;
}

.btn-plus:active i,
.btn-plus:focus i {
    transform: rotate(45deg);
    /* Rotaciona quando o botão está ativo ou focado */
    transition: transform 0.3s ease;
    outline: none;
    /* Remove o contorno quando o botão estiver focado */
    box-shadow: none;
}

.btn-plus:not(:focus) i {
    transform: rotate(0deg);
    /* Retorna para a posição original quando perde o foco */
    transition: transform 0.3s ease;
    /* Transição suave ao retornar */
}

button:focus {
    outline: none;
    /* Remove o contorno quando o botão estiver focado */
    box-shadow: none;
    /* Remove sombras adicionais, se houver */
}

.btn-send {
    padding: 3px;
    color: var(--color-silver);
}

.btn-send:disabled {
    color: var(--color-gray-secondary);
    cursor: auto;
}

.bi-paperclip,
.btn-send i {
    border-right: none;
    border-left: none;
    rotate: 45deg;
    margin-right: 10px;
}

.bi-paperclip {
    transform: scaleX(-1);
    font-size: 22px;
    border-right: none;
    border-left: none;
    rotate: 30deg;
}

.bi-arrow-left-right {
    rotate: 90deg;
}

.request-icon {
    display: flex;
    height: 100%;
    background-color: var(--color-danger);
    padding: 2px 8px;
    margin-right: 7px;
    border-radius: 4px;
}

.request-icon i {
    color: var(--color-white);
    font-size: 38px;
}

.p-splitter-gutter {
    margin-left: 15px;
    margin-right: 15px;
    padding: 0 2px;
}

.view-called {
    display: flex;
    position: relative;
    flex-direction: row-reverse;
    padding: 0 !important;
    margin: 0 !important;
    height: 100% !important;
}

.chat {
    display: grid;
    padding: 0;
    margin: 0;
    padding-top: 5px;
    background-size: cover;
    align-items: flex-end;
    overflow-y: auto;
    border-top: 1px solid #e2e2e4;
    min-height: calc(100% - 8.0vh);
    max-height: calc(100% - 8.0vh);
    background-color: var(--color-beige);
}

.chat-loading {
    display: grid;
    justify-content: center;
    background-color: var(--color-beige);
    overflow-y: auto;
    font-size: 13px;
    min-height: 100%;
    width: 100%;
    align-items: center;
    overflow-y: hidden;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.card-details {
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
}

.p-form-group {
    display: flex;
    height: 8.0vh;
    background-color: var(--color-gray);
    align-items: center;
    padding: 0 2px;
}

.p-inputgroup {
    height: 2.2rem;
    position: relative;
    display: flex;
    align-items: flex-end;
}

.input-group {
    border-radius: 15px;
}

.chat::-webkit-scrollbar {
    width: 8px;
}

.chat::-webkit-scrollbar-track {
    background-color: var(--color-beige);
}

.chat::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary);
    border-radius: 5px;
}

.p-placeholder,
textarea::placeholder {
    color: var(--color-gray);
}

.file-upload-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.p-button .p-component {
    display: none;
    visibility: hidden;
}

/* DataPanel.css */
.data-form {
    display: flex;
    font-size: medium;
}

.data-content {
    font-weight: 600;
}

.data-content label {
    color: var(--color-dark);
    font-weight: 100;
}

.sub-header {
    font-weight: 400;
}

.title-header {
    font-weight: 500;
}

.chat-comment {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--color-dark);
}

.comments {
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 5px;
    overflow-y: auto;
    padding-left: 5px;
}

.comments-items {
    position: absolute;
    right: 8px;
    background-color: #D9FDD3;
    padding: 0;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;
    top: 3px;
    color: #707070;
}

.comments .comments-items {
    display: none;
}

.comments-items:focus,
.comments:hover .comments-items {
    display: block;
}

.comments-items i {
    cursor: pointer;
}

.comment-recipient {
    display: flex;
    justify-content: space-between;
    color: #06CFB2;
    padding: 0 5px;
    padding-top: 7px;
}

.comment {
    position: relative;
    padding: 5px;
    padding-top: 8px;
    text-align: left;
}

.comment-text {
    white-space: pre-wrap;
}

.comment-file {
    margin-left: 5px;
    margin-right: 5px;
    color: var(--color-primary);
    cursor: pointer;
}

.comment-image span {
    font-size: 12px;
    margin-left: 5px;
    color: var(--color-silver);
}

.comment-file:hover {
    color: var(--color-primary);
}

.message-time {
    margin: 0;
    margin-top: 12px;
    margin-left: 8px;
    font-size: 12px;
    text-align: right;
    color: var(--color-silver);
    user-select: none;
}

.pull-right {
    float: right;
}

.send {
    display: flex;
    flex-direction: row;
    text-align: end;
    align-items: end;
    justify-content: end;
    background-color: #D9FDD3;
}

.message-main-sender {
    display: flex;
    justify-content: end;
    padding: 2px 10px;
    margin-left: 20%;
    max-width: 80%;
}

.message-main-receiver {
    display: flex;
    justify-content: start;
    max-width: 100%;
    padding: 2px 10px;
    margin-right: 20%;
    max-width: 80%;
}

.p-inputgroup {
    border-radius: 202px;
}

.image-file {
    display: flex;
    font-size: small;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 20px;
}

.image-file .bi {
    color: var(--color-silver);
}

.ball {
    display: flex;
    align-items: center;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    margin-right: 3px;
    margin-top: 2px;
}

.select-status {
    cursor: pointer;
}

.request-table {
    font-size: 15px;
    padding: 10px 7px;
}

.request-table .bi {
    font-size: 22px;
}

.request-table-status {
    display: flex;
    text-align: end;
    flex-direction: column;
}

.request-table:hover {
    cursor: pointer;
    background-color: var(--color-gray);
    transition: box-shadow 0.2s;
}

.p-dataview-header {
    padding: 4px 12px !important;
}

.select-status:hover {
    color: var(--color-primary)
}

.called-td {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 8px;
    gap: 8px;
}

.called-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 10px;
    gap: 8px;
}

.card-details {
    max-height: 84.5vh;
    overflow-y: auto;
}

.card-details::-webkit-scrollbar {
    width: 8px;
}

.card-details::-webkit-scrollbar-track {
    background-color: var(--color-white);
}

.card-details::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary);
    border-radius: 5px;
}

.called-card {
    min-height: 83vh;
    max-height: 85vh;
    height: 100% !important;
}

.accordion {
    border: 1px solid #e2e2e4;
    border-right: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
    padding: 16px 30px;
    position: relative;
}

.accordion-ticket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.accordion-ticket h5 {
    display: flex;
    font-size: 17px;
    padding-top: 9px;
    font-weight: 550;
    font-style: normal;
    letter-spacing: 0.3px;
}

.accordion-ticket i {
    display: flex;
    font-size: larger;
}

.data-info {
    font-size: 16px;
    letter-spacing: 0.3px;
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    border-top: 1.8px dotted #c2c2c4;
}

.data-info-title {
    display: block;
    color: var(--color-silver);
    min-width: 24vh;
    max-width: 24vh;
    user-select: none;
    margin-right: 5px;
}

.data-info-content {
    color: var(--color-dark);
    display: flex;
    align-items: center;
}

.profile-requester {
    font-size: 32px;
    color: var(--color-silver);
    display: flex;
    justify-content: center;
}

.attchaments {
    width: 96.9%;
    height: 20vh;
    border: 1px solid var(--color-white);
}

.form-input {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.card-body-0 .view-called {
    padding: 0 !important;
}

.p-carousel-indicators {
    display: none;
}

.p-carousel-item {
    display: flex;
    justify-content: center;
}

.data-title-comments {
    display: none;
    margin-bottom: 10px;
    border-bottom: 1px solid #d0d0d3;
}

.file-download {
    display: flex;
    font-size: 14px;
    background-color: var(--color-primary);
    margin-top: 5px;
    margin-bottom: 3px;
    border-radius: 3px;
    color: var(--color-white) !important;
    padding: 4px;
    padding-right: 12px;
    padding-left: 8px;
    border-color: var(--color-gray);
}

.file-download a {
    color: var(--color-white) !important;
    text-decoration: none;
}

@media (max-width: 600px) {
    .data-called {
        background-color: var(--color-white);
        position: absolute;
        left: 0;
        z-index: 1;
        min-height: calc(100% - 12vh);
        width: 100%;
    }

    .view-called {
        display: flex;
        flex-direction: column !important;
    }

    .called-card {
        max-height: none !important;
        overflow-y: auto;
    }

    .card-details {
        max-height: none !important;
        z-index: 2;
    }

    .profile-requester {
        display: none;
    }

    .profile-assignee {
        display: none;
    }

    .data-info {
        display: grid !important;
        flex-direction: row;
    }

    .data-info-title {
        width: 100% !important;
        max-width: 100vh;
    }

    .header-details {
        display: none;
    }

    .card-comments {
        display: none;
    }


    .chat {
        align-items: flex-end;
        min-height: calc(100vh - 4.5rem - 32px - 11px);
        max-height: calc(100vh - 4.5rem - 32px - 11px);
        padding-top: 2vh;
        padding-bottom: 4.0rem;
    }

    .p-form-group {
        position: absolute;
        bottom: 0;
        z-index: 100;
    }

    .p-dropdown-icon-left {
        display: flex;
    }

    .accordion {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.error-sfa {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: var(--color-primary);
}

.error-sfa label {
    cursor: pointer;
    margin-top: 15px;
}

.error-sfa label:hover {
    color: var(--color-silver);
}

.step {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    position: relative;
}

.step-title {
    font-weight: bold;
}

.step-subtitle {
    margin-left: 15px;
}

.step img {
    margin-top: 10px;
    margin-left: 15px;

}

.highlight {
    color: var(--color-primary);
}

.image-example-error {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.image-example-error span {
    font-size: 11px;
}

p {
    font-family: Roboto, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande";
    margin-bottom: 0px;
}

.p-overlaypanel-content {
    background-color: var(--color-beige) !important;
    padding: 10px 4px !important;
}

.p-items {
    display: flex;
    flex-direction: column;
}

.p-items button {
    padding: 10px 6px;
    background-color: var(--color-beige) !important;
    border: none !important;
    text-align: left !important;
}

.p-items button:hover {
    cursor: pointer;
    background-color: #eaecec !important;
    border-radius: 5px;
}

.p-items button:disabled:hover {
    cursor: auto;
    background-color: var(--color-beige) !important;
}

.p-items .bi-images {
    color: var(--color-primary);
}

.p-items .bi-arrow-repeat {
    color: var(--color-success);
}

.p-items .bi-check-square-fill {
    color: var(--color-success);
}

.p-items .bi-x-square-fill {
    color: var(--color-danger)
}

.overlay-items {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
}

.overlay-items div {
    display: flex;
    gap: 5px;
    cursor: pointer;
    padding: 3px 8px;
    z-index: 1000;
}

.overlay-items div:hover {
    background-color: var(--color-gray);
    display: flex;
    gap: 5px;
    cursor: pointer;
}