.dropdown-custom{
    height: 2.3rem;
    width: 100%;
    vertical-align: top;
    display: flex;
    align-items: center;
}

.dropdown-form-icon{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 2.5rem;
}

.dropdown-form-icon i{
    font-size: 27px;
    color: #474747;
}

