.card{
    background-color: #FFFFFF;
    color:  #000000;
    border-radius: 10px;
    display: flex;
    align-items: right;
    flex-direction: column;
}

.card-header{
    font-size: 20px;
    background-color: #FFFFFF;
}