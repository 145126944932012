.login {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-card{
    display: flex;
    justify-content: center;
    width: 30.0rem;
    padding: 30px 35px 30px 35px;
    border-radius: 5px;
    overflow-y: auto;
}

.logo {
    position: relative;
    margin-bottom: 20px;
}