.contact-card {
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 10px;
}
.contact-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}
.contact-hours {
    color: #3b3b3b;
    margin-bottom: 12px;
}
.whatsapp-button {
    background-color: #25D366; 
}

.whatsapp-button i{
    font-size: 18px;
}