.input-label {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18.5714px;
    text-decoration: none solid rgb(78, 94, 106);
    text-align: left;
    vertical-align: bottom;
    word-spacing: 0px;
    color: #40474d;
    user-select: none;
}

.i-error{
    color: red;
    font-size: 14px;
}

.input-text-sm {
    height: 2.3rem;
}

.p-placeholder, input::placeholder{
    color: rgb(189, 181, 181) !important;
    font-size: 13px;
}

.input-float{
    display: flex;
    font-weight: 300 !important;
    font-size: 13px;
    margin-left: 4px;
    margin-top: 1px;
}