.notificacao-gif {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    z-index: 2000;
    left: 7.3%;
    border-radius: 0 0 15px 15px !important;
    padding: 0 30px 5px 30px;
    color: white;
    background-color: #0A0A23;
}

.notificacao-gif img{
    border-radius: 0 0 15px 15px !important;

}