.assinatura tr {
    height: 25px;
}

.assinatura #root {
    height: 100px;
    font-family: "Libation Sans", "Arial";
}

.assinatura .pi {
    height: 22px;
}

.assinatura img {
    margin: 1.8px;
}

.assinatura .icon {
    margin-left: 10px;
}

.assinatura .bi {
    font-size: 18px;
    color: rgb(75, 74, 74);
    padding-bottom: 3px;
    margin-right: 5px;
}

.assinatura .name {
    width: 355px;
    margin-bottom: 10px;
}

.assinatura .form-control {
    width: 100%;
    margin-right: 15px;
}

.assinatura table {
    height: 25px;
}

.assinatura .row {
    justify-content: center;
    align-items: center;
}

.assinatura {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.assinatura .body {
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.assinatura a {
    text-decoration: none;
}

#linha-horizontal {
    width: 300px;
    border: 1px solid #000;
}

.assinatura strong {
    height: 10px;
}

.assinatura label {
    height: 1px;
    justify-content: center;
}

.assinatura .info {
    color: rgb(36, 35, 35);
    margin-bottom: 0;
}

.assinatura .info strong {
    height: 10px;
    color: rgb(70, 69, 69);
}

.assinatura .label {
    color: rgb(153, 151, 151);
}

.assinatura .icon {
    height: 16.0px;
}

.assinatura .box {
    width: 600px;
    position: absolute;
    height: 1000px;
    border: 2px solid green;
}

.assinatura .imagemContain {
    background-image: url('../../../assets/york-white.png');
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 99%;
    z-index: -1;
    width: 400px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
}

.assinatura .assinatura-download {
    transform: scale(0.7);
}

.p-mask input {
    padding-left: 13px;
}