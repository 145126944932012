.card-home {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 115px;
    color: var(--color-white);
    margin: 2px;
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
}

.card-home-title {
    display: flex;
    background-color: var(--color-danger);
    font-size: small;
    align-items: center;
    font-weight: 600;
    height: 75px;
    padding: 4px 6px;
}

.card-home-image {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: var(--color-black);
    height: 100%;
    background-color: var(--color-white);
}

.home-york {
   font-family: 'Calibri';
   height: calc(100vh - 4.3rem);
   display: grid;

}

.home-york footer {
    display: flex;
    color: var(--color-white);
    align-items: flex-end;
    vertical-align: bottom;
    justify-content: center;
    text-align: center;
 }

.icons i {
    font-size: 39px !important;
}

.icons .bi-whatsapp {
    color: #25D366;
}

.icons .bi-instagram {
    background: linear-gradient(45deg, #FFC35E 0%, #F78048 25%, #dc2743 50%, #D42E88 75%, #7C48C1 100%);
    -webkit-background-clip: text;
    color: transparent;
}

.icons .bi-linkedin {
    color: #0A66C2;
}

.icons .bi-envelope {
    font-size: 38px !important;
    margin-bottom: 8px;
    display: flex;
    height: 100%;
    color: #787F85;
}