.loading-page {
    width: 100%;
    height: 100vh !important;
    background-color: var(--color-white);
    position: fixed;
    z-index: 2;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.8s ease;
    z-index: 1200;
    opacity: 0.94;
    top: 0;
    text-align: center;
    transition: all 1s ease-in-out;
}

.loading-page img {
    z-index: 3;
    padding: 50px;
    height: 250px;
    /* width: 200px; */
    border-radius: 50%;
    animation: beat 0.8s infinite alternate;
}

@keyframes beat {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}